import React, { useEffect, useState } from 'react';
import { se, en } from './languageCopy';
import { api_url } from '../Constants/constants';

export const LanguageContext = React.createContext();

export const LanguageProvider = (props) => {
	const [country, setCountry] = useState(window.localStorage.getItem('locale'));
	const [access, setAccess] = useState(true);
	const [language, setLanguage] = useState({});

	const getCountryCodeFromAPI = async (ip) => {
		const staccsGeoRes = await fetch(`${api_url}/api/country?ip=${ip}`);
		console.log('geo res: ', staccsGeoRes);
		const staccsGeoData = await staccsGeoRes.json();
		const countryCode = staccsGeoData.country_code?.toLowerCase() || 'se';
		return countryCode;
	};

	const getIPAddress = async () => {
		try {
			const ipRes = await fetch('https://api.ipify.org?format=json');
			const ipData = await ipRes.json();
			const { ip } = ipData;
			return ip;
		} catch (err) {
			return '';
		}
	};

	useEffect(() => {
		const getCountry = async () => {
			const locale = window.localStorage.getItem('locale');
			if (!locale || locale === 'x') {
				try {
					const ip = await getIPAddress();
					const countryCode = await getCountryCodeFromAPI(ip);
					console.log('countrycode: ', countryCode);
					getAccess(countryCode);

					window.localStorage.setItem('locale', countryCode);

					setCountry(countryCode);
				} catch (error) {
					setCountry('se');
					setAccess(true);
				}
			}
		};

		getCountry();
	}, []);

	useEffect(() => {
		if (country === 'se') {
			setLanguage(se);
		} else {
			setLanguage(en);
		}
	}, [country]);

	const getAccess = (countryCode) => {
		if (countryCode === 'se') {
			setLanguage(se);
		} else {
			setLanguage(en);
		}

		if (
			['se', 'fi', 'ee', 'en', 'no', 'dk', 'lv', 'lt', 'ax'].includes(
				countryCode
			)
		) {
			setAccess(true);
		} else {
			setAccess(false);
			setLanguage(en);
		}
	};

	return (
		<LanguageContext.Provider
			value={{ language, setLanguage, country, access }}
		>
			{props.children}
		</LanguageContext.Provider>
	);
};
