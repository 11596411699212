import React, {useState} from 'react'
import HeroImgQueen from '../../../Assets/Images/hero-bg.jpg';

const NotAvailable = (props) => {
	const availableCountries = ['Sweden', 'Finland', 'Estonia', 'England', 'Norway', 'Denmark', 'Latvia', 'Lithuania', 'Åland'];
	const [showModal, setShowModal] = useState(false);

	const handleModalOpen = () => {
		setShowModal(true);
	};

	const handleModalClose = () => {
		setShowModal(false);
	};


	return (
		<div className="not-available">
			<div
				className="not-available__content"
				style={{
					background: `linear-gradient(110deg, rgba(23, 136, 141, 0.75) 10%, rgba(22, 58, 124, 0.5),rgba(54, 15, 48, 0.19),
            rgba(160, 78, 49, 0.85) 85%), url(${HeroImgQueen}) no-repeat center center/cover`,
				}}
			>
				<h1 className="not-available__content__heading">
					<span className="staccs">STACCS</span> IS CURRENTLY NOT AVAILABLE IN
					YOUR COUNTRY
				</h1>
				
				<h4 className="not-available__content__subheading">We are available in these countries for now:</h4>
				<ul className="not-available__content__list" >
					{availableCountries.map((country) => (
						<li key={country}>{country}</li>
					))}
				</ul>

					<h4 className="not-available__content__subheading">Is your country on the list but you're still seeing this message?</h4>
						<button className='not-available__content__button' onClick={handleModalOpen}>
						Click here!
						</button>

				{showModal && (
					<div className="modal">
						<div className="modal-content">
							<span className="close" onClick={handleModalClose}>&times;</span>
							<h2>Troubleshooting Instructions</h2>
							<p>If you are seeing this message and your country is on the list, please try the following steps:</p>
							<ol className="troubleshooting-steps">
								<li><strong>Clear your browser cache and cookies.</strong></li>
								<li><strong>Ensure you have allowed location access for this site:</strong> When prompted by your browser, select "Allow".</li>
								<li><strong>Ensure your browser is updated.</strong></li>
								<li><strong>Try accessing the site using a different browser or device.</strong></li>
								<li><strong>Disable any VPN or proxy services that may be affecting your location.</strong></li>
							</ol>
							<p>If these steps do not resolve the issue, please contact us at <a href="mailto:support@staccs.com">support@staccs.com</a>.</p>
						</div>
					</div>


				)}


				{/* <p>Sign up to get notified when we launch in your country.</p> */}
			</div>
		</div>
	);
};

export default NotAvailable;
